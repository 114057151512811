import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SwapPage from "../pages/swap/SwapPage.vue";
import WalletConnect from "../pages/connect/WalletConnect.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: SwapPage
        },
        {
            path: '/connect',
            name: 'connect',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: WalletConnect
            // component: () => import('../views/AboutView.vue')
        }
    ]
})

export default router
