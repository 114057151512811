
export class Chain {
	chainId: number
	name: string
	currency: string
	nativeCoinId: string
	decimals: number
	explorerUrl: string
	rpcUrl: string
	ankrId: string
	zyptoId: string | null
	coinGeckoId: string
	net: 'mainnet' | 'testnet'
	thumbnail?: string

	constructor(chain: Chain) {
		this.chainId = chain.chainId
		this.name = chain.name
		this.currency = chain.currency
		this.nativeCoinId = chain.nativeCoinId
		this.decimals = chain.decimals
		this.explorerUrl = chain.explorerUrl
		this.rpcUrl = chain.rpcUrl
		this.ankrId = chain.ankrId
		this.coinGeckoId = chain.coinGeckoId
		this.zyptoId = chain.zyptoId
		this.net = chain.net || 'mainnet'
		this.thumbnail = chain.thumbnail
	}
}
