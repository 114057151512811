import {defineStore} from 'pinia'
import {useWalletConnectStore} from './walletconnect.store'
import {Chain} from "../models/web3/Chain";

export const useBlockchainsStore = defineStore({
    // unique id of the store across your application
    id: 'blockchains',

    state: () => ({
        chains: [
            new Chain({
                chainId: 1,
                name: 'Ethereum',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://etherscan.io/',
                rpcUrl: 'https://rpc.ankr.com/eth/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'eth',
                zyptoId: 'ETH',
                coinGeckoId: 'ethereum',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/eth.svg',
            }),
            new Chain({
                chainId: 137,
                name: 'Polygon',
                currency: 'MATIC',
                nativeCoinId: 'matic-network',
                decimals: 18,
                explorerUrl: 'https://polygonscan.com',
                rpcUrl: 'https://rpc.ankr.com/polygon/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'polygon',
                zyptoId: 'Polygon',
                coinGeckoId: 'polygon-pos',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/matic-network.svg',
            }),
            new Chain({
                chainId: 42161,
                name: 'Arbitrum',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://arbiscan.io',
                rpcUrl: 'https://rpc.ankr.com/arbitrum/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'arbitrum',
                coinGeckoId: 'arbitrum-one',
                zyptoId: null,
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/arbiturm.svg',
            }),
            new Chain({
                chainId: 43114,
                name: 'Avalanche',
                currency: 'AVAX',
                nativeCoinId: 'avalanche-2',
                decimals: 18,
                explorerUrl: 'https://subnets.avax.network/',
                rpcUrl: 'https://rpc.ankr.com/avalanche/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'avalanche',
                zyptoId: 'Avalanche',
                coinGeckoId: 'avalanche',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/avalanche-2.svg',
            }),
            new Chain({
                chainId: 8453,
                name: 'Base',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://basescan.org/',
                rpcUrl: 'https://rpc.ankr.com/base/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'base',
                zyptoId: null,
                coinGeckoId: 'base',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/base.svg',
            }),
            new Chain({
                chainId: 56,
                name: 'BNB smart chain',
                currency: 'BNB',
                nativeCoinId: 'binancecoin',
                decimals: 8,
                explorerUrl: 'https://bscscan.com/',
                rpcUrl: 'https://rpc.ankr.com/bsc/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'bsc',
                zyptoId: 'BSC',
                coinGeckoId: 'binance-smart-chain',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/binancecoin.svg',
            }),
            new Chain({
                chainId: 250,
                name: 'Fantom',
                currency: 'FTM',
                nativeCoinId: 'fantom',
                decimals: 18,
                explorerUrl: 'https://ftmscan.com/',
                rpcUrl: 'https://rpc.ankr.com/fantom/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'fantom',
                zyptoId: 'Fantom',
                coinGeckoId: 'fantom',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/fantom.svg',
            }),
            new Chain({
                chainId: 14,
                name: 'Flare',
                currency: 'FLR',
                nativeCoinId: 'flare-networks',
                decimals: 18,
                explorerUrl: 'https://flare-explorer.flare.network/',
                rpcUrl: 'https://rpc.ankr.com/flare/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'flare',
                zyptoId: null,
                coinGeckoId: 'flare-network',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/flare-network.svg',
            }),
            new Chain({
                chainId: 100,
                name: 'Gnosis',
                currency: 'XDAI',
                nativeCoinId: 'xdai',
                decimals: 18,
                explorerUrl: 'https://gnosisscan.io/',
                rpcUrl: 'https://rpc.ankr.com/gnosis/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'gnosis',
                zyptoId: null,
                coinGeckoId: 'xdai',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/gnosis.svg',
            }),
            new Chain({
                chainId: 59144,
                name: 'Linea',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://lineascan.build/',
                rpcUrl: 'https://rpc.ankr.com/linea/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'linea',
                zyptoId: null,
                coinGeckoId: 'linea',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/linea.svg',
            }),
            new Chain({
                chainId: 10,
                name: 'Optimism',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://explorer.optimism.io/',
                rpcUrl: 'https://rpc.ankr.com/optimism/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'optimism',
                zyptoId: null,
                coinGeckoId: 'optimistic-ethereum',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/optimism.svg',
            }),
            new Chain({
                chainId: 1101,
                name: 'Polygon zkEVM',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://zkevm.polygonscan.com/',
                rpcUrl: 'https://rpc.ankr.com/polygon_zkevm/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'polygon_zkevm',
                zyptoId: null,
                coinGeckoId: 'polygon-zkevm',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/matic-network.svg',
            }),
            new Chain({
                chainId: 570,
                name: 'Rollux',
                currency: 'SYS',
                nativeCoinId: 'syscoin',
                decimals: 18,
                explorerUrl: 'https://explorer.rollux.com/',
                rpcUrl: 'https://rpc.ankr.com/rollux/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'rollux',
                coinGeckoId: 'rollux',
                zyptoId: null,
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/rollux.svg',
            }),
            new Chain({
                chainId: 534352,
                name: 'Scroll',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://scrollscan.com/',
                rpcUrl: 'https://rpc.ankr.com/scroll/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'scroll',
                zyptoId: null,
                coinGeckoId: 'scroll',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/scroll.svg',
            }),
            new Chain({
                chainId: 57,
                name: 'Syscoin',
                currency: 'SYS',
                nativeCoinId: 'syscoin',
                decimals: 18,
                explorerUrl: 'https://explorer.syscoin.org/',
                rpcUrl: 'https://rpc.ankr.com/syscoin/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'syscoin',
                zyptoId: null,
                coinGeckoId: 'syscoin',
                net: 'mainnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/syscoin.svg',
            }),

            new Chain({
                chainId: 43113,
                name: 'Avalanche Fuji',
                currency: 'AVAX',
                nativeCoinId: 'avalanche-2',
                decimals: 18,
                explorerUrl: 'https://subnets-test.avax.network/',
                rpcUrl: 'https://rpc.ankr.com/avalanche_fuji/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'avalanche_fuji',
                zyptoId: 'Fuji Testnet',
                coinGeckoId: 'avalanche',
                net: 'testnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/avalanche-2.svg',
            }),
            new Chain({
                chainId: 84532,
                name: 'Base Sepolia',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://sepolia.basescan.org/',
                rpcUrl: 'https://rpc.ankr.com/base_sepolia/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'base_sepolia',
                zyptoId: null,
                coinGeckoId: 'base',
                net: 'testnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/base.svg',
            }),
            new Chain({
                chainId: 17000,
                name: 'Ethereum Holesky',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://holesky.etherscan.io/',
                rpcUrl: 'https://rpc.ankr.com/eth_holesky/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'eth_holesky',
                zyptoId: null,
                coinGeckoId: 'ethereum',
                net: 'testnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/eth.svg',
            }),
            new Chain({
                chainId: 11155111,
                name: 'Ethereum Sepolia',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://sepolia.etherscan.io/',
                rpcUrl: 'https://rpc.ankr.com/eth_sepolia/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'eth_sepolia',
                zyptoId: null,
                coinGeckoId: 'ethereum',
                net: 'testnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/eth.svg',
            }),
            new Chain({
                chainId: 420,
                name: 'Optimism Testnet',
                currency: 'ETH',
                nativeCoinId: 'ethereum',
                decimals: 18,
                explorerUrl: 'https://sepolia-optimism.etherscan.io/',
                rpcUrl: 'https://rpc.ankr.com/optimism_sepolia/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'optimism_testnet',
                zyptoId: null,
                coinGeckoId: 'optimistic-ethereum',
                net: 'testnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/optimism.svg',
            }),
            new Chain({
                chainId: 80002,
                name: 'Polygon Amoy',
                currency: 'ETH',
                nativeCoinId: 'matic-network',
                decimals: 18,
                explorerUrl: 'https://www.oklink.com/amoy/',
                rpcUrl: 'https://rpc.ankr.com/polygon_amoy/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c',
                ankrId: 'polygon_amoy',
                zyptoId: null,
                coinGeckoId: 'polygon-pos',
                net: 'testnet',
                thumbnail: 'https://rng-public-resources.s3.eu-central-1.amazonaws.com/token-icons/matic-network.svg',
            }),
        ] as Chain[],
        _selectedChain: undefined as Chain | undefined,
    }),

    getters: {
        mainnets: (state) => state.chains.filter((chain) => chain.net === 'mainnet'),
        testnets: (state) => state.chains.filter((chain) => chain.net === 'testnet'),
        selectedChain: (state) =>
            state._selectedChain ? state._selectedChain : (state._selectedChain = state.chains[0]),
    },

    actions: {
        getByChainId(id: number) {
            return this.chains.find((el) => el.chainId === id)
        },
        getByAnchrId(id: string) {
            return this.chains.find((el) => el.ankrId === id)
        },
        getByZyptoId(id: string) {
            return this.chains.find((el) => el.zyptoId === id)
        },
        getByCoinGeckoId(id: string) {
            return this.chains.find((el) => el.coinGeckoId === id)
        },
        async changeChain(id: number) {
            const walletConnectStore = useWalletConnectStore()
            console.log(id)

            console.log(walletConnectStore?.modal)
            console.log(walletConnectStore?.modal?.switchNetwork)

            await walletConnectStore?.modal?.switchNetwork(id)

            return (this._selectedChain = this.getByChainId(id))
        },
    },
})
