import {defineStore} from 'pinia'
import {ethers} from "ethers";
import {Web3Modal} from "@web3modal/ethers5";
import {th} from "@lifi/widget/i18n";

export const useWalletConnectStore = defineStore({
    // unique id of the store across your application
    id: 'WalletConnectStore',

    state: () => ({
        modal: undefined as undefined | Web3Modal,
        walletAddress: undefined as undefined | string,
    }),

    getters: {
        browserProvider: (state): ethers.providers.Web3Provider | undefined => {
            // state.walletConnectProvider = state.modal?.getWalletProvider()
            const walletProvider = state.modal?.getWalletProvider()
            if (!walletProvider) return

            return new ethers.providers.Web3Provider(walletProvider)
        },
    },

    actions: {
        async disconnect() {
            this.modal?.disconnect()
            this.walletAddress = undefined
        },

        async getSigner() {
            if (!this.modal) return (this.walletAddress = undefined)
            const provider = this.browserProvider
            if (provider) return provider.getSigner()
        },

        async switchChain(chainId: number) {
            if (!this.modal) return
            await this.modal?.switchNetwork(chainId)
            const provider = new ethers.providers.Web3Provider(this.modal.getWalletProvider())
            return await provider.getSigner()
        },

        async requestSwitchNetwork(chainId: number) {
            const provider = this.browserProvider;
            if (!provider) throw new Error('Provider not available');

            try {
                // This is how we send the switch network request
                await provider.send("wallet_switchEthereumChain", [{chainId}]);

            } catch (switchError: any) {
                // This error code indicates that the chain has not been added to MetaMask
                if (switchError.code === 4902) {
                    try {
                        // If it's not added, let's add it!
                        await provider.send("wallet_addEthereumChain", [{chainId}]);
                    } catch (addError) {
                        // handle "add" error
                    }
                }
                // handle other "switch" errors
            }
        },


        // async getCurrentWallet() {
        //     if (!this.modal) return (this.walletAddress = undefined)
        //     const provider = this.browserProvider
        //     const signer = await provider.getSigner()
        //     this.walletAddress = await signer.getAddress()
        //     return this.walletAddress
        // },
    },
})
