<template>
  <section class="blue-card-button">
    <div v-if="img != null" class="blue-card-button__img">
      <img :src="img" alt=""/>
    </div>
    <div v-else-if="icon != null" class="blue-card-button__icon">
      <span :class="icon"></span>
    </div>
    <div class="blue-card-button__wrapper">
      <div class="blue-card-button__content">
        <span class="blue-card-button__content--title h7">{{ title }}</span>
        <span class="blue-card-button__content--content h8">{{ content }}</span>
      </div>
      <span class="icon-arrowright blue-card-button__wrapper--arrow"></span>
    </div>
  </section>
</template>

<script lang="ts" setup>
defineProps({
  title: {
    required: true,
    type: String,
  },
  content: {
    required: true,
    type: String,
  },
  icon: {
    required: false,
    type: String,
    default: null,
  },
  img: {
    required: false,
    type: String,
    default: null,
  },
})
</script>

<script lang="ts">
export default {
  name: 'blue-card-button',
}
</script>
<style lang="sass" scoped>
@import "../../assets/sass/abstracts/variables"
@import "../../assets/sass/abstracts/mixins"

.blue-card-button
  display: flex
  align-items: center
  gap: 13px
  background: $background-card
  padding: 12px
  border-radius: 6px
  user-select: none
  width: 100%
  transition: scale .2s ease-in-out

  &:active
    scale: 0.97

  &__icon
    @include flex-center
    border-radius: 6px
    padding: 4px
    background: $background-card
    aspect-ratio: 1 / 1
    font-size: 65px
    color: $white

  &__img
    @include flex-center
    border-radius: 6px
    padding: 4px
    aspect-ratio: 1 / 1
    font-size: 65px


  &__wrapper
    width: 100%
    display: flex
    align-items: center
    gap: 16px
    justify-content: space-between

    &--arrow
      font-size: 8px
      color: $grey-4

  &__content
    display: flex
    flex-direction: column
    gap: 8px

    &--title
      font-weight: 500

    &--content
      color: $grey-4
</style>
