<template>
  <default-page-layout class="widget">
    <Widget v-if="!isLoading" :config="config" class="swap"/>
  </default-page-layout>
</template>

<script lang="ts">
import {applyPureReactInVue} from 'veaury';
// This is a React component
import {LiFiWidget} from '@lifi/widget';

export default {
  components: {
    // Use HOC 'applyReactInVue' or 'applyPureReactInVue'
    // Basic: applyReactInVue(LiFiWidget),
    Widget: applyPureReactInVue(LiFiWidget)
  },
}
</script>

<script lang="ts" setup>
import {WidgetConfig, HiddenUI} from '@lifi/widget';
import DefaultPageLayout from "../layouts/DefaultPageLayout.vue";
import {onBeforeMount, ref} from "vue";
import {useWalletConnectStore} from "../stores/walletconnect.store";
import router from "../router";
import {useBlockchainsStore} from "../stores/blockchains.store";
import {ethers} from "ethers";
import {ChainId} from "@lifi/sdk";

const walletConnectStore = useWalletConnectStore()
const blockchainsStore = useBlockchainsStore()
const isLoading = ref(true)

const config: WidgetConfig = {
  integrator: 'Renegade',
  fee: 0.012,
  sdkConfig: {
    rpcs: {
      [ChainId.POL]: ["https://rpc.ankr.com/polygon/cbd1df1dc253621b0fa07fc0304b826bb34a8481ea25cf8d6b427bb8b0d5c20c"]
    }
  },

  chains: {
    deny: [ChainId.ERA, ChainId.MOR, ChainId.MOO, ChainId.FUS, ChainId.BOB, ChainId.AUR, ChainId.MAM, ChainId.RSK, 34443, 5000, 81457 ]
  },

  variant: 'default',
  subvariant: "split",
  appearance: "dark",
  hiddenUI: [HiddenUI.PoweredBy, HiddenUI.Appearance],
  theme: {
    shape: {
      borderRadius: 12
    },
    typography: {
      fontFamily: "Inter, sans-serif"
    },
    palette: {
      primary: {
        main: "#4B1A8A"
      },
      secondary: {
        main: "#fc9f33"
      },
      background: {
        default: '#00000000', // bg color container
        paper: '#0085FF2B', // bg color for cards
      },
      grey: {
        300: '#B79FFA40', // border light theme
        800: '#B79FFA40', // border dark theme
      },
    }
  }
}

onBeforeMount(async () => {
  isLoading.value = true
  if (!walletConnectStore.modal || !walletConnectStore.walletAddress) return router.push('/connect')

  console.log(await walletConnectStore.getSigner())

  config.walletManagement = {
    signer: await walletConnectStore.getSigner() as ethers.Signer,
    connect: async () => {
      const signer = await walletConnectStore.getSigner()
      if (!signer) return router.push('/connect')
      return signer
    },
    disconnect: async () => {
      await walletConnectStore.disconnect()
      router.push('/connect')
    },
    switchChain: async (chainId: number) => {
      return await walletConnectStore.switchChain(chainId)
    },
  }
  isLoading.value = false
})

</script>

<style lang="sass">
div[__use_react_component_wrap]
  & > div
    height: 100dvh
    max-height: 100%


.css-2o24zz
// main wrapper
 height: 100% !important

.MuiIconButton-sizeSmall
  background: #0085FF !important

.css-19hzhsr-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root, .css-7yc712-MuiInputBase-root
  background: none !important

.MuiMenu-paper
  backdrop-filter: blur(20px) brightness(0.5)

#widget-relative-container-\:r0\:, #widget-scrollable-container-\:r0\:
  height: 100%
  max-height: 100% !important
  max-width: 450px !important

.MuiTab-root

  &.MuiButtonBase-root.Mui-selected
    background: linear-gradient(180deg, #8A22D1 0%, #591198 100%) padding-box, linear-gradient(0deg, #610582 0%, rgba(243, 172, 255, 0.9) 90%) border-box !important
    border-radius: 8px
    border: 1px solid transparent


.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary
  background: linear-gradient(180deg, #8A22D1 0%, #591198 100%) padding-box, linear-gradient(0deg, #610582 0%, rgba(243, 172, 255, 0.9) 90%) border-box !important
  border-radius: 8px
  border: 1px solid transparent
</style>