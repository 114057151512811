<script lang="ts" setup>
</script>

<template>
  <article class="default-page-layout">

    <section class="default-page-layout__container">

      <slot name="header"></slot>

      <slot name="default"></slot>
        <div class="pink-flare"></div>
        <div class="purple-flare"></div>
        <div class="blue-flare"></div>
    </section>
  </article>
</template>

<style lang="sass" scoped>
@import "src/assets/sass/abstracts/variables"
@import "src/assets/sass/abstracts/mixins"

.default-page-layout
  @include flex-center
  align-items: flex-start
  height: 100dvh
  width: 100%
  position: relative
  overflow: hidden

  &__container
    height: 100%
    width: 100%
    max-width: 450px
    position: relative


.page-container
  padding-bottom: 60px

.flares-background
  @include flex-center
  position: fixed
  z-index: -1
  background: $background
  bottom: 0
  width: 100%
  height: 100%

.blue-flare, .purple-flare, .pink-flare
  z-index: -1
  width: 140px
  height: 140px
  filter: blur(114px)
  position: absolute
  pointer-events: none

.pink-flare
  width: 200px
  height: 200px
  background: #B10DFF
  top: -100px
  right: -100px


.purple-flare
  background: #770DFF
  bottom: 0

.blue-flare
  background: #1574CB
  bottom: -100px
  right: 100px
</style>
